import { useEffect, useState } from "react";

export const useParams = (
  screenIds: string,
  memberIds: string,
  isUpdate: number
) => {
  const [url, setUrl] = useState("");
  const env = localStorage.getItem("env");
  const userId = localStorage.getItem("user");
  const password = localStorage.getItem("pass");
  const company = localStorage.getItem("company");
  const pcId = localStorage.getItem("pc_id");
  const osType = localStorage.getItem("type");
  const useMedia = localStorage.getItem("use_media");
  const startNotice = localStorage.getItem("start_notice");
  const useCamera = localStorage.getItem("use_camera");
  const viewVideo = localStorage.getItem("view_video");
  const onlyOnce = localStorage.getItem("only_once");
  // const memberIds = localStorage.getItem("member_ids");
  // const screenIds = localStorage.getItem("screenIds");

  useEffect(() => {
    let count = 0;
    let tmpUrl = `${process.env.REACT_APP_RURA_FRAME}/frame`;
    if (userId && userId !== "null") {
      tmpUrl += `${count === 0 ? "?" : "&"}user=${userId}`;
      count += 1;
    }
    if (password && password !== "null") {
      tmpUrl += `${count === 0 ? "?" : "&"}pass=${password}`;
      count += 1;
    }
    if (company && company !== "null") {
      tmpUrl += `${count === 0 ? "?" : "&"}company=${company}`;
      count += 1;
    }
    if (pcId && pcId !== "null") {
      tmpUrl += `${count === 0 ? "?" : "&"}pc_id=${pcId}`;
      count += 1;
    }
    if (osType && osType !== "null") {
      tmpUrl += `${count === 0 ? "?" : "&"}os_type=${osType}`;
      count += 1;
    }
    if (useMedia && useMedia !== "null") {
      tmpUrl += `${count === 0 ? "?" : "&"}use_media=${useMedia}`;
      count += 1;
    }
    if (startNotice && startNotice !== "null") {
      tmpUrl += `${count === 0 ? "?" : "&"}start_notice=${startNotice}`;
      count += 1;
    }
    if (useCamera && useCamera !== "null") {
      tmpUrl += `${count === 0 ? "?" : "&"}use_camera=${useCamera}`;
      count += 1;
    }
    if (viewVideo && viewVideo !== "null") {
      tmpUrl += `${count === 0 ? "?" : "&"}view_video=${viewVideo}`;
      count += 1;
    }
    if (onlyOnce && onlyOnce !== "null") {
      tmpUrl += `${count === 0 ? "?" : "&"}only_once=${onlyOnce}`;
      count += 1;
    }
    if (memberIds && memberIds !== "null") {
      tmpUrl += `${count === 0 ? "?" : "&"}member_id=${memberIds}`;
      count += 1;
    }
    if (screenIds && screenIds !== "null") {
      tmpUrl += `${count === 0 ? "?" : "&"}screen_ids=${screenIds}`;
      count += 1;
    }
    setUrl(tmpUrl);

    // eslint-disable-next-line react-hooks/exhaustive-deps -- not necessary
  }, [screenIds, memberIds, isUpdate]);

  return {
    url,
    userId,
    password,
    company,
    pcId,
    osType,
    useMedia,
    startNotice,
    useCamera,
    viewVideo,
    onlyOnce,
    memberIds,
    screenIds,
    env
  };
};
