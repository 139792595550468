import { Box, Text, Image, Button, Flex } from "@chakra-ui/react";
import { useRef, useEffect, useState } from "react";
import { Scanner } from "src/pages/components/Scanner";

export const QRreader = ({
  setPage,
  memberIds,
  setMemberIds,
  isUpdate,
  setUpdate,
}: {
  setPage: (page: string) => void;
  memberIds: string[];
  setMemberIds: (memberIds: string[]) => void;
  isUpdate: number;
  setUpdate: (isUpdate: number) => void;
}) => {
  const [alertText, setAlertText] = useState<string>("");
  const area = `${process.env.PUBLIC_URL}/image/area.svg`;
  const videoRef = useRef<HTMLVideoElement>(null);
  const setStream = () => {
    navigator.mediaDevices
      .getUserMedia({
        audio: false,
        video: true,
      })
      .then((stream) => {
        if (videoRef && videoRef.current) {
          videoRef.current.srcObject = stream;
        }
      });
  };

  useEffect(() => {
    setStream();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- not necessary
  }, []);

  return (
    <Box textAlign="center" sx={{ msOverflowStyle: "none" }}>
      <Box mt="192px">
        <Text fontSize="32px">
          バーコードを枠内に収まるように映してください
        </Text>
      </Box>
      <Button
        position="absolute"
        left="0"
        top="0"
        cursor="pointer"
        onClick={() => {
          memberIds.push("0000000000000002");
          setMemberIds(memberIds);
          console.log("0000000000000002を読み込みました");
        }}
      >
        0000000000000002を読み込む
      </Button>
      <Flex width="950px" m="30px auto">
        <Box
          position="relative"
          height="484px"
          width="678px"
          background="grey"
          cursor="pointer"
          onClick={() => setPage("customer_service")}
        >
          <Scanner
            memberIds={memberIds}
            setMemberIds={setMemberIds}
            setAlertText={setAlertText}
            update={() => setUpdate(isUpdate + 1)}
          />
          {/* <video
          ref={videoRef}
          muted
          autoPlay
          height="584px"
          width="878px"
          style={{ objectFit: "fill" }}
        ></video> */}
          <Box position="absolute" top="120px" left="207px">
            <Image src={area} />
          </Box>
        </Box>
        <Box ml="20px" width="250px">
          {alertText && <Text>{alertText}</Text>}
          <Text>読み取り済み会員ID</Text>
          {memberIds.map((memberId: string, index: number) => (
            <Text key={index}>会員ID：{memberId}</Text>
          ))}
        </Box>
      </Flex>

      <Button
        width="295px"
        height="54px"
        background="#000000"
        color="#FFFFFF"
        borderRadius="16px"
        cursor="pointer"
        onClick={() => {
          setPage("customer_service");
        }}
      >
        <Text fontSize="32px">次へ</Text>
      </Button>
    </Box>
  );
};
