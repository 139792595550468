import React, { useState, useEffect } from "react";
import { Menu } from "src/pages/components/Menu";
import { Overview } from "src/pages/components/Overview";
import { QRreader } from "src/pages/components/QRreader";
import { CustomerService } from "src/pages/components/CustomerService";
import { Footer } from "src/pages/components/Footer";
import { Header } from "src/pages/components/Header";

function Main() {
  const [selectedMenu, setSelectedMenu] = useState<string | null>(null);
  const selectMenu = (menuText: string | null) => {
    setSelectedMenu(menuText);
    if (menuText) {
      setPage("overview");
    } else {
      setPage("menu");
    }
  };
  const [page, setPage] = useState<string>("menu");
  const [screenIds, setScreenIds] = useState<string[]>([]);
  const [memberIds, setMemberIds] = useState<string[]>([]);
  const [isUpdate, setUpdate] = useState<number>(0);

  useEffect(() => {
    if (page !== "menu") {
      if (page === "overview") screenIds.push("000002");
      else if (page === "reader") screenIds.push("000003");
      else if (page === "customer_service") screenIds.push("000004");
      setScreenIds(screenIds);
    } else {
      setScreenIds(["000001"]);
      setMemberIds([]);
    }
    setUpdate(isUpdate + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- not necessary
  }, [page]);
  return (
    <div>
      <Header screenIds={screenIds} memberIds={memberIds} isUpdate={isUpdate} />
      {page === "menu" ? (
        <Menu selectMenu={selectMenu} />
      ) : page === "overview" ? (
        <Overview selectdMenu={selectedMenu} setPage={setPage} />
      ) : page === "reader" ? (
        <QRreader
          setPage={setPage}
          memberIds={memberIds}
          setMemberIds={setMemberIds}
          isUpdate={isUpdate}
          setUpdate={setUpdate}
        />
      ) : (
        <CustomerService
          selectdMenu={selectedMenu}
          setPage={setPage}
          memberIds={memberIds}
        />
      )}
      <Footer setPage={setPage} viewMenu={page !== "menu"} />
    </div>
  );
}

export default Main;
