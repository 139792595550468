import { useEffect } from "react";

export const Scanner = ({
  memberIds,
  setMemberIds,
  setAlertText,
  update,
}: {
  memberIds: string[];
  setMemberIds: (memberIds: string[]) => void;
  setAlertText: (alertText: string) => void;
  update: () => void;
}) => {
  const Quagga = require("quagga");

  const config = {
    inputStream: {
      type: "LiveStream",
      constraints: {
        width: { min: 450, max: 878 },
        height: { min: 300, max: 584 },
        facingMode: "environment",
        aspectRatio: { min: 1, max: 2 },
      },
    },
    locator: {
      patchSize: "medium",
      halfSample: true,
    },
    numOfWorkers: 2,
    frequency: 10,
    decoder: {
      readers: [
        "code_128_reader",
        "ean_reader",
        "ean_8_reader",
        "code_39_reader",
        "code_39_vin_reader",
        "codabar_reader",
        "upc_reader",
        "upc_e_reader",
        "i2of5_reader",
        "2of5_reader",
        "code_93_reader",
      ],
    },
    locate: true,
  };

  useEffect(() => {
    Quagga.init(config, (err: any) => {
      if (err) {
        console.log(err, "error msg");
      }
      Quagga.start();
      return () => {
        Quagga.stop();
      };
    });

    Quagga.onDetected(detected);
  }, []);

  const detected = (result: any) => {
    // onDetected(result.codeResult.code);
    if (result.codeResult.format === "codabar") {
      console.log("code", result.codeResult.code);
      if (result.codeResult.code.length === 18) {
        const regex = /[^0-9]/g;
        const memberId = result.codeResult.code.replace(regex, "");
        if (!memberIds.includes(memberId)) {
          memberIds.push(memberId);
          setMemberIds(memberIds);
          setAlertText("会員IDを読み込みました");
          setTimeout(() => {
            setAlertText("");
          }, 3000);
          update();
        }
      }
    }
  };

  return (
    <div
      id="interactive"
      className="viewport"
      style={{
        height: "484px",
        width: "678px",
        position: "absolute",
        top: 0,
        left: 0,
      }}
    />
  );
};
