import { Box, Text } from "@chakra-ui/react";
import { menuList } from "src/const";

export const CustomerService = ({
  selectdMenu,
  setPage,
  memberIds,
}: {
  selectdMenu: string | null;
  setPage: (page: string) => void;
  memberIds: string[];
}) => {
  const menu = menuList.find((menu) => menu.value === selectdMenu);
  return (
    <Box textAlign="center">
      <Box
        textAlign="left"
        position="absolute"
        top="0"
        left="0"
        height="50px"
        p="10px"
      >
        <Text m="0" fontSize="18px">
          読み込んだ会員情報
        </Text>
        {memberIds.map((memberId: string, index: number) => (
          <Box key={index}>
            <Text m="5px 0px 0px 0px" fontSize="24px">
              会員番号: {memberId}
            </Text>
            <Text m="0" fontSize="24px">
              登録名: a123456789
            </Text>
          </Box>
        ))}
      </Box>
      <Text fontSize="64px" mt="200px">
        {menu?.text}
      </Text>
      <Box marginTop="200px">
        <Text fontSize="24px" fontWeight="500">
          わからない場合は、通話開始ボタンを押して、
          <br />
          リモートコンシェルジュに通話してください。
        </Text>
      </Box>
    </Box>
  );
};
