import { Box, Button, Text, Image } from "@chakra-ui/react";
import {useParams} from "src/pages/hooks/useParams"

export const Footer = ({
  setPage,
  viewMenu = true,
}: {
  setPage: (page: string) => void;
  viewMenu?: boolean;
}) => {
  
  const {env} = useParams("null", "null", 0);
  const logo = `${process.env.PUBLIC_URL}/image/${env === "koshidaka" ? "logo_manekineko.svg" : "rura-ogp.png"}`;

  return (
    <Box>
      {viewMenu && (
        <Button
          position="absolute"
          left="33px"
          bottom="32px"
          width="295px"
          height="54px"
          background="#000000"
          color="#FFFFFF"
          borderRadius="16px"
          cursor="pointer"
          onClick={() => {
            setPage("menu");
          }}
        >
          <Text fontSize="32px">メニュー選択に戻る</Text>
        </Button>
      )}
      <Image
        src={logo}
        position="absolute"
        bottom="0"
        right="0"
        width="200px"
        height="150px"
        cursor="pointer"
        onClick={() => {
          window.location.href = "/setting";
        }}
      />
    </Box>
  );
};
