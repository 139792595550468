import { Box, Button, Text, Image } from "@chakra-ui/react";
import { useRef, useState } from "react";
import { useParams } from "src/pages/hooks/useParams";

export const Header = ({
  screenIds,
  memberIds,
  isUpdate,
}: {
  screenIds: string[];
  memberIds: string[];
  isUpdate: number;
}) => {
  const callLogo = `${process.env.PUBLIC_URL}/image/phone-outgoing.svg`;
  const discallLogo = `${process.env.PUBLIC_URL}/image/phone-missed.svg`;
  const frameRef = useRef<HTMLIFrameElement>(null);
  const { url } = useParams(
    screenIds.length > 0 ? screenIds.join(",") : "null",
    memberIds.length > 0 ? memberIds.join(",") : "null",
    isUpdate
  );
  const call = (status: "start" | "finish") => {
    if (frameRef && frameRef.current && process.env.REACT_APP_RURA_FRAME) {
      frameRef.current.src = status === "start" ? url : "";
      setView(status === "start");
      if (status === "start") {
        console.warn("frame url", url);
      }
    }
  };
  const [view, setView] = useState(false);
  return (
    <Box
      textAlign="left"
      position="absolute"
      top="0"
      right="5px"
      height="200px"
      width="488px"
    >
      <Box
        background="#F7F7F7"
        height="100px"
        width="488px"
        position="relative"
      >
        <iframe
          allow="camera *;microphone *"
          ref={frameRef}
          title="rura-frame"
          height="100px"
          width="488px"
          src=""
        />
        {!view && (
          <Box pt="15px" zIndex="10" position="absolute" top="0" width="488px">
            <Text textAlign="center" mt="0px">
              ご不明な点がありましたら、
              <br />
              リモートコンシェルジュが対応いたしますので
              <br />
              「通話開始ボタン」を押してください。
            </Text>
          </Box>
        )}
      </Box>
      <Box m="16px">
        <Button
          height="67px"
          width="212px"
          background="#023894"
          borderRadius="16px"
          border="0px"
          m="6px"
          cursor="pointer"
          onClick={() => call("start")}
        >
          <Image src={callLogo} mr="10px" />
          <Text color="#FFFFFF" fontSize="32px">
            通話開始
          </Text>
        </Button>
        <Button
          height="67px"
          width="212px"
          background="#E7212A"
          borderRadius="16px"
          m="6px"
          border="0px"
          cursor="pointer"
          onClick={() => call("finish")}
        >
          <Image src={discallLogo} mr="10px" />
          <Text color="#FFFFFF" fontSize="32px">
            通話終了
          </Text>
        </Button>
      </Box>
    </Box>
  );
};
