import { Box, Text } from "@chakra-ui/react";
import { menuList } from "src/const";

export const Menu = ({
  selectMenu,
}: {
  selectMenu: (menuText: string | null) => void;
}) => {
  return (
    <Box textAlign="center">
      <Box mt="187px">
        <Text fontSize="32px">
          いらっしゃいませ。
          <br />
          お好みのメニューをお選びください。
        </Text>
      </Box>
      <Box width="900px" m="0 auto">
        {menuList.map((menu, index: number) => (
          <Box
            key={index}
            width="380px"
            height="140px"
            background={
              menu.text === "会計"
                ? "#E7212A"
                : menu.text === "雑談"
                ? "#023894"
                : "#FCCF00"
            }
            borderRadius="16px"
            m="32px"
            sx={{ display: "inline-block" }}
            onClick={() => {
              selectMenu(menu.value);
            }}
            cursor="pointer"
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              width="100%"
              height="100%"
            >
              <Text
                mt="40px"
                fontSize="56px"
                fontWeight="500"
                color={
                  menu.text === "会計" || menu.text === "雑談"
                    ? "#FFFFFF"
                    : "#000000"
                }
              >
                {menu.text}
              </Text>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
