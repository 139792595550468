import { Box, Text, Button } from "@chakra-ui/react";
import { menuList } from "src/const";

export const Overview = ({
  selectdMenu,
  setPage,
}: {
  selectdMenu: string | null;
  setPage: (page: string) => void;
}) => {
  const menu = menuList.find((menu) => menu.value === selectdMenu);
  return (
    <Box textAlign="center">
      <Box mt="200px">
        <Text fontSize="64px" m="0px">
          {menu?.text}
        </Text>
        <Text fontSize="32px" m="0px">
          会員カードを読み込ませると予約画面に進みます。
        </Text>
      </Box>

      <Button
        height="140px"
        width="812px"
        background="#FCCF00"
        borderRadius="16px"
        border="0px"
        m="30px 20%"
        cursor="pointer"
        onClick={() => setPage("reader")}
      >
        <Text fontSize="56px" fontWeight="500">
          会員カードを読み込ませる
        </Text>
      </Button>
      <Box>
        <Text fontSize="24px" fontWeight="500">
          ※会員カードをお持ちでない場合は、
          <br />
          隣のリモートコンシェルジュでお問い合わせください。
        </Text>
      </Box>
    </Box>
  );
};
