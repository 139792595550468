import React, { useState } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Input,
  Box,
  Text,
  Button,
  Flex,
} from "@chakra-ui/react";
import { useParams } from "src/pages/hooks/useParams";

function Setting() {
  const {
    userId,
    password,
    company,
    pcId,
    osType,
    useMedia,
    startNotice,
    useCamera,
    viewVideo,
    onlyOnce,
    env
    // memberId,
    // screenIds,
  } = useParams("null", "null", 0);
  const [settingList, setSettingList] = useState<
    {
      key: string;
      value: any;
      must: boolean;
      remark?: string;
    }[]
  >([
    { key: "env", value: env ? env : "koshidaka", must: true, remark: "popular or koshidaka" },
    { key: "user", value: userId, must: false },
    { key: "pass", value: password, must: false },
    { key: "company", value: company, must: true },
    { key: "pc_id", value: pcId, must: true },
    { key: "type", value: osType, must: false },
    { key: "use_media", value: useMedia ? useMedia : "false", must: false },
    { key: "start_notice", value: startNotice, must: false },
    { key: "use_camera", value: useCamera ? useCamera : "false", must: false },
    { key: "view_video", value: viewVideo ? viewVideo : "false", must: false },
    { key: "only_once", value: onlyOnce ? onlyOnce : "true", must: false },
    // { key: "member_id", value: memberId, must: false },
    // { key: "screen_ids", value: screenIds, must: false },
  ]);

  const saveValue = (index: number, value: string) => {
    settingList[index].value = value;
    setSettingList(settingList);
  };

  const saveStorage = () => {
    settingList.map((setting: { key: string; value: any }) => {
      localStorage.setItem(setting.key, setting.value);
    });
    alert("設定を保存しました");
  };

  return (
    <div style={{ textAlign: "center", width: "100%" }}>
      <Box>
        <Text>設定画面</Text>
      </Box>
      <Box m="50px auto" width="450px">
        <TableContainer>
          <Table variant="striped">
            <Thead>
              <Tr>
                <Th>キー名</Th>
                <Th>設定値</Th>
              </Tr>
            </Thead>
            <Tbody>
              {settingList.map(
                (setting: { key: string; value: any; remark?: string }, index: number) => (
                  <Tr key={index}>
                    <Td>{setting.key}</Td>
                    <Td>
                      {
                        <Input
                          type="text"
                          defaultValue={
                            setting.value !== "null" ? setting.value : ""
                          }
                          onChange={(e: any) => {
                            saveValue(index, e.target.value);
                          }}
                        />
                      }
                    </Td>
                    {setting.remark && <Td>{setting.remark}</Td>}
                  </Tr>
                )
              )}
            </Tbody>
          </Table>
        </TableContainer>
        <Flex justifyContent="space-between">
          <Button mt="20px" onClick={saveStorage}>
            保存
          </Button>
          <Button
            mt="20px"
            onClick={() => {
              window.location.href = "/";
            }}
          >
            メイン画面に遷移する
          </Button>
        </Flex>
      </Box>
    </div>
  );
}

export default Setting;
